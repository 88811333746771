<template>
  <page-layout linkDefault1="/" title="Brand Ambassador" :backButton="true">
    <div class="ml-8 mr-8 pb-12">
      <div class="flex flex-col items-start mt-7 space-y-6">
        <p class="font-poppins font-bold text-sm leading-5 text-left">What is the Brand Ambassador Program (BAP)?</p>
        <p class="font-poppins font-normal text-sm leading-5 text-left">
          It’s a marketing campaign that puts our company's message in the hands of ambassadors with specific goals in
          mind, such as improving sales, building brand recognition, and helping students make the best out of Tutoruu.
        </p>

        <p class="font-poppins font-normal text-sm leading-5 text-left">As a brand ambassador, you will be expected to
          publicize Tutoruu’s message through user-generated content, affiliate marketing, and awareness stunts.</p>
        <p class="font-poppins font-normal text-sm leading-5 text-left">Your role is to publicize
          Tutoruu on and off campus, measured by Key Performance Indicators (KPI)</p>
        <p class="font-poppins font-normal text-sm leading-5 text-left">The compensation for this position is 5000 EGP
          per semester. If you think you have what it takes, fill in the form below to receive more details</p>
      </div>

      <br>
      <a href="https://docs.google.com/forms/d/1hCjAU3NaNZf3PD-aUwa8oQAbe1QhRY_Lm_OfSrtHZx4/edit" target="_blank">
        <Button>Apply now</Button>
      </a>
      <!-- <div class="flex flex-col mt-2 space-y-6">
        <div class="flex flex-row items-center justify-between">
          <p class="font-poppins text-sm leading-5 font-normal">Name :</p>
          <textarea class="w-48 h-9 rounded-3xl border-2 border-gray-200 " />
        </div>
        <div class="flex flex-row justify-between">
          <p class="font-poppins text-sm leading-5 font-normal"> Phone :</p>
          <textarea class="w-48 h-9 rounded-3xl border-2 border-gray-200 " />
        </div>
        <div class="flex flex-row justify-between">
          <p class="font-poppins text-sm leading-5 font-normal">university :</p>
          <textarea class="w-48 h-9 rounded-3xl border-2 border-gray-200 " />
        </div>
        <div class="flex flex-row justify-between">
          <p class="font-poppins text-sm leading-5 font-normal">Class :</p>
          <textarea class="w-48 h-9 rounded-3xl border-2 border-gray-200 " />
        </div>

        <div class="flex flex-col justify-between">
          <p class="font-poppins text-sm leading-5 w-44 font-normal">Previous extracurricular activities :</p>
          <textarea class="h-20 rounded-3xl border-2 border-gray-200 " />
        </div>


      </div> -->
    </div>
  </page-layout>
</template>

<script>
import Button from "../../components/Feed/Button.vue";
import PageLayout from "../../components/base/pageLayout.vue";
export default {
  components: { PageLayout, Button, },
  data() {
    return {
      user: null,
    };
  },

  created() {
    this.setUser();
  },
  methods: {
    setUser() {
      this.user = this.$store.state.user;
    },
  },
};
</script>

